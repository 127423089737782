import { Link } from "gatsby";
import styled from "@emotion/styled";
import React from "react";
import LanguageMenu from "../i18n/languageMenu";
import { withTrans } from "../i18n/withTrans";

const Content = styled.div`
  max-width: 860px;
  padding: 1rem 1.0875rem;
  font-size: 1.2rem;
`;

const NavLink = styled(Link)`
  color: black;
  margin-right: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const HomeLink = styled(NavLink)`
  margin-left: 0;
`;

const SiteHeader = styled.header`
  background: transparent;
  display: flex;
  align-content: center;
  justify-content: center;
`;

const Header = ({ t }) => (
  <SiteHeader>
    <Content>
      <p>
        <HomeLink to="/">{t("navigation.home")}</HomeLink>
        <NavLink to="/about/">{t("navigation.about")}</NavLink>
        <NavLink to="/works/">{t("navigation.works")}</NavLink>
        <LanguageMenu />
      </p>
    </Content>
  </SiteHeader>
);

export default withTrans(Header);
