/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { withTrans } from "../i18n/withTrans";

import Header from "./header";
import "./layout.css";
import "./animation.css";

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 1rem;
  padding-top: 0;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
`;

const Layout = ({ children, t }) => {
  return (
    <>
      <Header siteTitle={t("home.title")} />
      <Content>
        <main>{children}</main>
        <Footer>
          <div>© {new Date().getFullYear()}, Hector Leiva</div>
        </Footer>
      </Content>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTrans(Layout);
