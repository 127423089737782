// import i18n from "i18next";
// import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { reactI18nextModule } from "react-i18next";
import i18next from "i18next";

i18next
  // .use(Backend)
  // .use(LanguageDetector)
  // .use(reactI18nextModule)
  .init({
    fallbackLng: "en",

    resources: {
      es: {
        translations: require("../locales/es/translations.json"),
      },
      en: {
        translations: require("../locales/en/translations.json"),
      },
    },

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    returnObjects: true,

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  });

i18next.languages = ["es", "en"];

export default i18next;
