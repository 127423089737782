import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageMenu = (props) => {
  const { i18n } = useTranslation();

  const [values, setValues] = useState({
    language: "en",
  });

  function handleChange(event) {
    i18n.changeLanguage(event.target.value);

    setValues((oldValues) => ({
      ...oldValues,
      language: event.target.value,
    }));
  }

  return (
    <select value={values.language} onChange={(e) => handleChange(e)}>
      <option value={"en"}>🇺🇸</option>
      <option value={"es"}>🇪🇸</option>
    </select>
  );
};

export default LanguageMenu;
